import { Vector3 } from 'three'

export const resetViewAnimation = ({ camera, cameraConfig, animation, initialTargetPosition, controlsRef, setAnimation, setIsUserInteracting }) => {
  if (animation === 'reseting') {
    const cameraPosition = camera.position.clone()
    // Calculate the distance from the origin to the camera position
    let distance = +cameraPosition.distanceTo(new Vector3(0, 0, 0)).toFixed(3)
    // const maxDistance = +cameraConfig.maxDistance.toFixed(3)
    const configCameraPositionY = cameraConfig.cameraPositionCoordinates[1]
    const cameraPositionY = +cameraPosition.y.toFixed(3)
    const positionDiffYAxis = cameraPositionY > configCameraPositionY ? cameraPositionY - configCameraPositionY : configCameraPositionY - cameraPositionY

    const zoomFactor = cameraConfig.maxDistance / distance
    const {x, z} = cameraPosition.clone().multiplyScalar(zoomFactor)
    const magicalScaleNumber = 5.7
    const targetPosition = new Vector3(x* magicalScaleNumber, cameraConfig.cameraPositionCoordinates[1]*magicalScaleNumber, z*magicalScaleNumber)

    camera.position.lerp(targetPosition, 0.05)
    camera.updateProjectionMatrix()

    // Smoothly interpolate OrbitControls target
    if (controlsRef.current) {
      controlsRef.current.target.lerp(initialTargetPosition, 0.05)
      controlsRef.current.update()
    }
    // TODO: proper approach but has to be checked more thoroughly since may contain regression
    // if (maxDistance - distance <= 0.1 && positionDiffYAxis <= 0) {
    // staring rotation little bit earlier
    if (positionDiffYAxis <= 2) {
      setIsUserInteracting(false)
    }
    if (positionDiffYAxis <= 0.1) {
      setAnimation(null)
    }
  }
}

export const zoomInAnimation = ({ camera, cameraConfig, animation, setAnimation }) => {
  if (animation === 'zoomIn') {
    const cameraPosition = camera.position.clone()
    // Calculate the distance from the origin to the camera position
    const distance = cameraPosition.distanceTo(new Vector3(0, 0, 0))
  
    if (distance - cameraConfig.minDistance > 0.1) {
      // Calculate the zoom factor
      const zoomFactor = cameraConfig.minDistance / distance
      // Calculate the zoomed-in coordinates
      const camPos = cameraPosition.clone().multiplyScalar(zoomFactor)
  
      camera.position.lerp(camPos, 0.03)
      camera.updateProjectionMatrix()
    }
    if (distance - cameraConfig.minDistance <= 0.1) {
      setAnimation(null)
    }
  }
}

export const zoomOutAnimation = ({ camera, cameraConfig, animation, setAnimation }) => {
  if (animation === 'zoomOut') {
    const cameraPosition = camera.position.clone()
    // Calculate the distance from the origin to the camera position
    const distance = cameraPosition.distanceTo(new Vector3(0, 0, 0))

    if (cameraConfig.maxDistance - distance > 0.1) {
      // Calculate the zoom factor
      const zoomFactor = cameraConfig.maxDistance / distance
      // Calculate the zoomed-in coordinates
      const camPos = cameraPosition.clone().multiplyScalar(zoomFactor)
  
      camera.position.lerp(camPos, 0.03)
      camera.updateProjectionMatrix()
    }
    if (cameraConfig.maxDistance - distance <= 0.1) {
      setAnimation(null)
    }
  }
}