import { useState, useEffect } from 'react'

const useDetectDevice = () => {
  // Synchronously determine the initial device type
  const getInitialDeviceType = () => {
    const mobileQuery = '(max-width: 1023px)' // Adjusted to include iPad Pro
    const desktopQuery = '(min-width: 1024px)' // Desktop now starts at 1367px
    const landscapeQuery = '(orientation: landscape)'

    const isMobile = window.matchMedia(mobileQuery).matches
    const isDesktop = window.matchMedia(desktopQuery).matches
    const isLandscape = window.matchMedia(landscapeQuery).matches

    return {
      isMobile,
      isTablet: false, // Tablet category is removed, since we treat iPads as mobile
      isDesktop,
      isMobileLandscape: isMobile && isLandscape,
    }
  }

  const [deviceType, setDeviceType] = useState(getInitialDeviceType)

  useEffect(() => {
    const mobileQuery = '(max-width: 1023px)' // Adjusted to include iPad Pro
    const desktopQuery = '(min-width: 1024px)' // Desktop now starts at 1367px
    const landscapeQuery = '(orientation: landscape)'

    const mobileMediaQuery = window.matchMedia(mobileQuery)
    const desktopMediaQuery = window.matchMedia(desktopQuery)
    const landscapeMediaQuery = window.matchMedia(landscapeQuery)

    const handleMediaChange = () => {
      setDeviceType({
        isMobile: mobileMediaQuery.matches,
        isTablet: false, // Tablet category is removed, since we treat iPads as mobile
        isDesktop: desktopMediaQuery.matches,
        isMobileLandscape: mobileMediaQuery.matches && landscapeMediaQuery.matches,
      })
    }

    // Add event listeners
    mobileMediaQuery.addEventListener('change', handleMediaChange)
    desktopMediaQuery.addEventListener('change', handleMediaChange)
    landscapeMediaQuery.addEventListener('change', handleMediaChange)

    // Initial check
    handleMediaChange()

    // Clean up listeners on unmount
    return () => {
      mobileMediaQuery.removeEventListener('change', handleMediaChange)
      desktopMediaQuery.removeEventListener('change', handleMediaChange)
      landscapeMediaQuery.removeEventListener('change', handleMediaChange)
    }
  }, [])

  return deviceType
}

export default useDetectDevice