import React from 'react'
import { ReactComponent as ResetIcon } from './icons/reset.svg'
import { sendIFrameEvent } from '../../helpers/iframe'

function Reset3DViewer({ className = '' }) {
  return (
    <button
      onClick={() => {
        sendIFrameEvent('analytics', {event: 'reset_tapped'})
        document.dispatchEvent(new CustomEvent('reset-view-event'))
      }}
      type="button"
      className={`h-[32px] w-[32px] bg-[#fff] rounded-full flex items-center justify-center shadow-md relative ${className}`}
    >
      <ResetIcon />
    </button>
  )
}

export default Reset3DViewer
