export function plainHide({ mesh }) {
  mesh.material = mesh.material.clone()
  mesh.visible = false
  mesh.opacity = 0
}

export function plainShow({ mesh }) {
  mesh.material = mesh.material.clone()
  mesh.visible = true
  mesh.opacity = 1
}


export function fadeOut({ mesh, animationState = 'idle', setAnimation = () => {}, fadeDuration = 0.15 }) {
  if (animationState === 'idle' && mesh.material.opacity > 0) {
    // Clone material to ensure each object has its own independent material
    mesh.material = mesh.material.clone()
    mesh.material.transparent = true  // Ensure transparency is enabled
    let startTime = performance.now() // Capture start time
    setAnimation('fadingOut')
  
    function animateFadeOut(time) {
      let elapsed = (time - startTime) / 1000  // Time passed in seconds
      let opacity = Math.max(1 - (elapsed / fadeDuration), 0)
      mesh.material.opacity = opacity
  
      if (opacity > 0) {
        requestAnimationFrame(animateFadeOut)  // Continue fading out
      } else {
        mesh.visible = false // Hide object when fade-out completes
        setAnimation('idle')
      }
    }
  
    requestAnimationFrame(animateFadeOut)  // Start fade-out animation
  }
}

export function fadeIn({ mesh, animationState = 'idle', setAnimation = () => {}, fadeDuration = 0.15 }) {
  if (animationState === 'idle' && mesh.material.opacity !== 1) {
    // Clone material to ensure each object has its own independent material
    mesh.material = mesh.material.clone()
    mesh.material.transparent = true
    mesh.visible = true
    let startTime = performance.now()
    setAnimation('fadingIn')
  
    function animateFadeIn(time) {
      let elapsed = (time - startTime) / 1000  // Time passed in seconds
      let opacity = Math.min(elapsed / fadeDuration, 1)  // Calculate new opacity
      mesh.material.opacity = opacity
  
      if (opacity < 1) {
        requestAnimationFrame(animateFadeIn)  // Continue fading in
      } else {
        mesh.material.opacity = 1  // Explicitly set opacity to 1 when fade-in completes
        setAnimation('idle')
      }
    }
    requestAnimationFrame(animateFadeIn)  // Start fade-in animation
  }
}