import { ExposureControl } from '../extensions/Exposure'

export function Lighting() {
  const defaultIntensity = 0.5
  const defaultPosition = [0, 1, 0]

  return (
    <>
      <ExposureControl />
      <hemisphereLight />
      <ambientLight intensity={1.5} />
      <directionalLight
        intensity={defaultIntensity}
        position={defaultPosition}
      />
    </>
  )
}