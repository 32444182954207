
export function priceSum(arr) {
  return arr.reduce((acc, x) => acc + Number(x), 0)
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function pluralEnds(count) {
  return count > 1 ? 's' : ''
}

export const getValueFromObject = (obj, path, def) => {
  let result = obj
  const p = path.replace(/\[/g, '.').replace(/]/g, '').split('.')

  p.forEach((level) => {
    result = Object.prototype.hasOwnProperty.call(result, level) ? result[level] : undefined
  })

  if (result === undefined) {
    return def
  }

  return result
}

export const sort = (key) => (a, b) => {
  const A = getValueFromObject(a, key, '')
  const B = getValueFromObject(b, key, '')

  if (Number.isInteger(+A) && Number.isInteger(+B)) {
    return A - B
  }

  const nameA = A.toUpperCase()
  const nameB = B.toUpperCase()

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

export function appEnvPrefix() {
  if (!process.env.REACT_APP_RUNTIME_ENV) {
    throw new Error('REACT_APP_RUNTIME_ENV is not specified')
  }
  if (isProductionEnv()) {
    return 'production'
  }
  return process.env.REACT_APP_RUNTIME_ENV
}

export function isProductionEnv() {
  return process.env.REACT_APP_RUNTIME_ENV === 'production'
}

export function isProductionBuild() {
  return process.env.NODE_ENV === 'production'
}

export function isDebugMode() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  return urlParams.get('debug') === 'true' && !isProductionEnv()
}

export function getAppName() {
  return (!isProductionBuild() && localStorage.getItem('reactApp')) || process.env.REACT_APP_NAME
}

export function arrayUniqueByKey(array, key) {
  return [...new Map(array.map((item) => [item[key], item])).values()]
}

export function whitespacesToUnderscore(str) {
  return str.replaceAll(' ', '_').replaceAll('#', 'NUM')
}

export function objectCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function copyToClipboard(text) {
  try {
    console.log(text)
    navigator.clipboard.writeText(text)
  } catch (error) {
    console.log(error)
    console.log(text)
  }
}

export function compareObjects(objA, objB) {
  return JSON.stringify(objA) === JSON.stringify(objB)
}


export function randomID(){
  return Math.random().toString(36).slice(2)
}

export function safeConfigurationOptions(options){
  return options || {}
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export function parseBoolean(value = ''){
  if(value === null){
    return false
  }
  if(typeof value === 'boolean'){
    return value
  }
  return value.toLowerCase() === "true" || value.toLowerCase() === "1"
}