import { useEffect } from "react"

export function useParentWindowEvent(targetEvent, cb) {
  useEffect(() => {
    function onParentMessage(event) {
      if (event.data.event === targetEvent) {
        cb(event.data.payload)
      }
    }
    // Inside the iframe
    window.addEventListener('message', onParentMessage)
    return () => {
      window.removeEventListener('message', onParentMessage)
    }
  }, [cb, targetEvent])
}
