import { ApolloClient, InMemoryCache } from '@apollo/client'
import { apiHost } from './api'

export function client() {
  return new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  })
}

export async function query(query) {
  try {
    return await client().query(
      query.variables
        ? {
            ...query,
          }
        : {
            errorPolicy: 'all',
            query: query,
          },
    )
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function mutate(variables, mutation, params = {}) {
  try {
    const result = await client().mutate({
      errorPolicy: 'all',
      variables,
      mutation,
    })
    if (result.errors && result.errors.length > 0) {
      throw new Error(result)
    }
    return result
  } catch (error) {
    console.error(error)
    throw error
  }
}
