import { gql } from '@apollo/client'
import { query } from '../helpers/graphql'

export async function getAssets() {
  return await query({
    variables: {},
    query: gql`
      query Assets {
        assets {
          id
          uuid
          category {
            id
            wall_based
          }
        }
      }
    `,
  })
}
