import { useEffect } from 'react';
import { PMREMGenerator, LinearToneMapping } from 'three'
import { useThree } from '@react-three/fiber'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment'

export function ExposureControl({ exposure = 0.35 }) {
  const { gl, scene } = useThree()

  useEffect(() => {
    gl.toneMappingExposure = exposure
    gl.toneMapping = Number(LinearToneMapping)

    const pmremGenerator = new PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()

    // Use RoomEnvironment for 'neutral' environment
    const roomEnvironment = new RoomEnvironment()
    const envMap = pmremGenerator.fromScene(roomEnvironment, 0.05).texture

    // Apply the environment map to the scene
    scene.environment = envMap

    // Clean up the PMREMGenerator
    return () => {
      pmremGenerator.dispose()
    }
  }, [gl, scene, exposure])

  return null
}