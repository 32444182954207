import './App.css'
import { useContext, useEffect, useState } from 'react'
import { AppConfigContext, AppConfig } from './context/AppConfig'
import ModelLoader from './components/FloorPlan3DViewer/components/threejs/models/ModelLoader'
import FloorPlan3DViewer from './components/FloorPlan3DViewer'
import { useParentWindowEvent } from './hooks/useParentWindowEvent'
import Reset3DViewer from './components/Reset3DViewer'
import { ZoomControls } from './components/ZoomControls'
import { getAssets } from './services/assets'
import ModelsPrefetcher from './components/ModelsPrefetcher'
import { sendIFrameEvent } from './helpers/iframe'
import HotJar from './analytics/hotjar'


function App() {
  const [modelUrl, setModelUrl] = useState()
  const [transitionTrigger, setTransitionTrigger] = useState(0)
  const { url, loader, controls, initial_angle, hotjar_site_id, bg_hex_color, block_analytics, hide_background } =
    useContext(AppConfigContext)

  useEffect(() => {
    if (block_analytics) {
      console.warn(`3D Viewer : Analytics is blocked`)
    }
  }, [block_analytics])

  useParentWindowEvent('url-change', ({ url, transition, angle }) => {
    setModelUrl(url)
    if (transition) {
      setTransitionTrigger(Date.now())
    }
    if (angle) {
      setRotationAngle(angle)
    }
  })

  useParentWindowEvent('screenshot', () => {
    window.captureModelScreenshot((screenshotURL) => {
      sendIFrameEvent('screenshot-ready', { url: screenshotURL })
    })
  })

  useEffect(() => {
    if (url && url !== 'empty') {
      setModelUrl(atob(url))
    }
  }, [url])

  const [modelLoaded, setModelLoaded] = useState(false)

  const [assets, setAssets] = useState(null)
  useEffect(() => {
    ;(async () => {
      if (!assets) {
        const { data } = await getAssets()
        var mappedAssets = data?.assets?.map((asset) => ({ [asset.uuid]: asset }))
        setAssets(Object.assign({}, ...mappedAssets))
      }
    })()
  }, [assets])

  const [rotationAngle, setRotationAngle] = useState(initial_angle ? Number(initial_angle) : 2)

  useParentWindowEvent('reset-view', () => {
    document.dispatchEvent(new CustomEvent('reset-view-event'))
  })

  useEffect(() => {
    if (bg_hex_color) {
      document.body.style.background = `#${bg_hex_color}`
    }
  }, [bg_hex_color])

  useEffect(() => {
    if (hide_background && !bg_hex_color) {
      document.body.style.background = `transparent`
    } else {
      document.body.classList.add('default-bg')
    }
  }, [hide_background, bg_hex_color])

  if (!url && url !== 'empty') {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div className="text-[52px]">🫠</div>
        <div>Invalid params provided</div>
      </div>
    )
  }

  return (
    <>
      {controls && (
        <>
          <div
            className={`transition-opacity flex items-center p-[5] absolute bottom-[24px] left-[24px] z-[10] ${
              modelLoaded ? '' : 'opacity-50'
            }`}
          >
            <Reset3DViewer />
          </div>
          <div
            className={`transition-opacity items-center p-[5] absolute bottom-[24px] right-[24px] z-[10] hidden lg:flex ${
              modelLoaded ? '' : 'opacity-50'
            }`}
          >
            <ZoomControls />
          </div>
        </>
      )}
      <ModelsPrefetcher />
      {modelUrl && (
        <>
          <ModelLoader
            onLoadingChange={(loading) => {
              setModelLoaded(!loading)
            }}
            url={modelUrl}
            showLoader={loader !== 'false'}
            className={({ hasBlobs }) =>
              `${
                hasBlobs
                  ? 'absolute bottom-[16px] portrait:bottom-[60px] portrait:left-[14px] !justify-start lg:bottom-[24px] lg:left-[initial] !lg:justify-center '
                  : ''
              } bg-transparent`
            }
          >
            {(url) => (
              <FloorPlan3DViewer
                url={url}
                rotationAngle={rotationAngle}
                transitionTrigger={transitionTrigger}
                assets={assets}
                className="h-full w-full relative"
              />
            )}
          </ModelLoader>
        </>
      )}
      {hotjar_site_id && !block_analytics && <HotJar siteId={hotjar_site_id} />}
    </>
  )
}

function Config() {
  return (
    <AppConfig>
      <App />
    </AppConfig>
  )
}

export default Config
