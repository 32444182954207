import React, { useContext, useEffect, useState } from 'react'
import './styles.css'
import { Canvas, useThree } from '@react-three/fiber'

import { CameraExtension, Helpers } from './components/threejs/extensions'
import { useParentWindowEvent } from '../../hooks/useParentWindowEvent'
import { Lighting, InteriorModel } from './components/threejs/models'
import useDetectDevice from '../../hooks/useDetectDevice'
import { AppConfigContext } from '../../context/AppConfig'
import { DebugUtils } from './components/threejs/extensions/DebugUtils'
import { Leva } from 'leva'

function UnitViewer({ url, rotationAngle, assets, selectedLine }) {
  const { camera, gl } = useThree()
  const { isDesktop } = useDetectDevice()
  const { isWideMode } = useContext(AppConfigContext)
  const [model, setModel] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      if (isDesktop) {
        if (!document.getElementById('sidebar')) {
          return
        }
        const { innerWidth: width, innerHeight: height } = window
    
        const w = width - (isWideMode ? 0 : document.getElementById('sidebar').offsetWidth)
        const h = height - document.getElementById('footer').offsetHeight
    
        // Update camera aspect ratio and projection matrix
        camera.aspect = w / h
        camera.updateProjectionMatrix()
    
        // Update renderer size
        gl.setSize(w, h)
      }
    }

    // Attach event listener
    window.addEventListener('resize', handleResize)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [camera, gl, isDesktop, isWideMode])

  return (
    <CameraExtension selectedLine={selectedLine} rotationAngle={rotationAngle} model={model?.scene}>
      {/* <Helpers /> */}
      <Lighting />
      <InteriorModel
        url={url}
        assets={assets}
        onLoad={(gltf) => setModel(gltf)}
      />
    </CameraExtension>
  )
}

const transitionDuration = 150

function FloorPlan3DViewer({ className = '', url, rotationAngle, assets, transitionTrigger = 0 }) {
  const [angle, setAngle] = useState()
  const [animationClass, setAnimation] = useState('opacity-0')
  const [modelUrl, setModelUrl] = useState()
  const [selectedLine, setSelectedLine] = useState()
  const config = useContext(AppConfigContext)

  // reduces model flickering when switching lines
  useEffect(() => {
    if (!modelUrl) {
      setModelUrl(url)
    } else {
      setTimeout(() => setModelUrl(url), 150)
    }
  }, [url, modelUrl])

  useParentWindowEvent('select-line', (line) => {
    setAnimation('opacity-0')
    setTimeout(() => {
      setSelectedLine(line)
    }, 100)
  })

  useEffect(() => {
    // setTimeout(() => {
      setAngle(rotationAngle)
      // Assuming that line change is done with transition, perform reset of the view to proceed automatic rotation
      document.dispatchEvent(new CustomEvent('reset-view-event'))
    // }, transitionDuration + 50)
  }, [transitionTrigger, rotationAngle])

  useEffect(() => {
    setTimeout(() => {
      setAnimation('opacity-100')
    }, 600)
  }, [modelUrl])

  return (
    <div className={`${className} ${animationClass} transition-opacity linear delay-250`} style={{ transitionDuration: `${transitionDuration / 1000}s` }}>
      <Leva collapsed={false} hidden={!Boolean(config?.debug)} />
      <Canvas shadows>
        {config.debug && <DebugUtils />}
        <UnitViewer
          url={modelUrl}
          rotationAngle={angle}
          assets={assets}
          selectedLine={selectedLine}
        />
      </Canvas>
    </div>
  )
}

export default FloorPlan3DViewer
