import { useRef, useState, Children, cloneElement, useEffect, useContext } from 'react'
import { useControls } from 'leva'
import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { useThree, useFrame } from '@react-three/fiber'
import { Vector3 } from 'three'
import { AppConfigContext } from '../../../../../../context/AppConfig'
import { zoomInAnimation, zoomOutAnimation, resetViewAnimation } from './animations'
import { calculateCameraPositionAgainstModel, calculateCameraDistance } from './utils'
import { sendIFrameEvent } from '../../../../../../helpers/iframe'

export function CameraExtension({ rotationAngle, model, children, selectedLine }) {
  rotationAngle = !isNaN(Number(rotationAngle)) ? Number(rotationAngle) : 0
  const fov = 35
  const initialTargetPosition = new Vector3(0, 0, 0)
  const rotateSpeed = 0.75
  const { offsetWidth: width, offsetHeight: height } = document.getElementById('root')
  let aspectRatio = +(width / height).toFixed(2)
  const controlsRef = useRef()
  const { camera } = useThree()
  const [isUserInteracting, setIsUserInteracting] = useState(false)
  const [animation, setAnimation] = useState(null)
  const [cameraConfig, setCameraConfig] = useState({})
  const [selectedLineState, setSelectedLine] = useState()
  const [modelDistanceMaximized, setModelDistanceMaximized] = useState()
  const [,setPositon] = useControls('Camera', () => ({ position: { value: camera.position, disabled: true } }))

  useEffect(() => {
    if (model) {
      if (selectedLine && selectedLine.name !== selectedLineState) {
        setSelectedLine(selectedLine.name)
        setModelDistanceMaximized(true)
      } else {
        setModelDistanceMaximized(false)
      }
    }
  }, [selectedLine, model])

  useEffect(() => {
    let cameraPositionCoordinates = null
    let cameraPosition = null
    let cameraDistances = null
    
    if (model) {
      if (rotationAngle) {
        cameraPositionCoordinates = calculateCameraPositionAgainstModel({ model, rotationAngle, fov, aspectRatio })
        cameraPosition = new Vector3(...cameraPositionCoordinates)
        cameraDistances = calculateCameraDistance({ cameraPosition, model, rotationAngle, fov, aspectRatio })
      }

      if (cameraConfig.cameraPosition) {
        if (modelDistanceMaximized || aspectRatio !== cameraConfig.aspectRatio) {
          const distance = camera.position.clone().distanceTo(new Vector3(0, 0, 0))
          const zoomFactor = cameraDistances.maxDistance / distance
          cameraPosition = camera.position.clone().multiplyScalar(zoomFactor)
        } else {
          cameraPosition = camera.position.clone()
        }
      }

      setCameraConfig({
        ...cameraDistances,
        aspectRatio,
        cameraPosition,
        cameraPositionCoordinates,
      })
    }
  }, [model, modelDistanceMaximized, rotationAngle, aspectRatio])

  // Real-time updates with useFrame for smooth interpolation
  useFrame(() => {
    resetViewAnimation({ camera, cameraConfig, animation, initialTargetPosition, controlsRef, setAnimation, setIsUserInteracting })
    zoomInAnimation({ camera, cameraConfig, animation, setAnimation })
    zoomOutAnimation({ camera, cameraConfig, animation, setAnimation })

    const {x, y, z} = camera?.position
    if (x && y && z) {
      setPositon({ position: [x,y,z] })
    }
  })

  useEffect(() => {
    const listener = () => {
      setIsUserInteracting(false)
    }
    const resetViewAction = () => {
      if (isUserInteracting) {
        setAnimation('reseting')
      }
    }

    document.addEventListener('reset-view-event', resetViewAction)
    document.addEventListener('proceed-animation', listener)

    return () => {
      document.removeEventListener('reset-view-event', resetViewAction)
      document.removeEventListener('proceed-animation', listener)
    }
  })

  useEffect(() => {
    if (isUserInteracting) {
      sendIFrameEvent('analytics', {event: 'engaged_with_model'})
    }
  }, [isUserInteracting])

  const renderChildren = () => {
    return Children.map(children, (child) => {
      return cloneElement(child, {
        controlsRef,
      })
    })
  }

  useEffect(() => {
    const adjustZoom = ({ detail }) => {
      if (detail.direction === 'zoom-in') {
        setAnimation('zoomIn')
      }
      if (detail.direction === 'zoom-out') {
        setAnimation('zoomOut')
      }
    }

    document.addEventListener('adjust-zoom', adjustZoom)

    return () => {
      document.removeEventListener('adjust-zoom', adjustZoom)
    }
  }, [cameraConfig, camera])

  const config = useContext(AppConfigContext)

  return (
    <>
      <OrbitControls
        ref={controlsRef}
        autoRotate={!isUserInteracting}
        autoRotateSpeed={rotateSpeed}
        enableTouchZoom={!animation}
        enableTouchRotate={!animation}
        enableZoom={!animation}
        enableRotate={!animation}
        enablePan={false}
        camera={camera}
        minPolarAngle={0.02}
        maxPolarAngle={Math.PI / 2 - 0.1} // don't allow to go approx 5 degrees lower horizontally
        onStart={() => {
          setIsUserInteracting(true)
        }} // Disable animation when the user starts interacting
        onEnd={() => {
          setIsUserInteracting(true)
          setAnimation(null)
        }}
        maxDistance={cameraConfig?.maxDistance}
        minDistance={cameraConfig?.minDistance}
      />
      <PerspectiveCamera
        makeDefault
        fov={fov}
        position={cameraConfig?.cameraPosition}
        far={cameraConfig?.far}
        near={cameraConfig?.near || 1}
      />
      {renderChildren()}
    </>
  )
}
