import React, { useEffect, useRef, useState } from "react"
import { useGLTF } from '@react-three/drei'
import { useControls } from 'leva'
import { useThree, useFrame } from "@react-three/fiber"
import { Mesh, Cache, Vector3, Box3, AxesHelper, LinearMipMapLinearFilter, LinearFilter } from 'three'
import { isDebugMode } from '../../../../../helpers/general'
import { fadeIn, fadeOut, plainHide, plainShow } from '../../../animations'

function hideOrShowWallBasedItem({ meshObject, assets, camera, animation, setAnimation, fadeDuration, hideAnimation = fadeOut, showAnimation = fadeIn }) {
  const [uuid] = meshObject.name?.split('_') // need this hack since objects from Blender are being exported each time with version prefix e.g 9d4396ae-b508-42cf-b8b4-0ead99fb16d004_1

  if (assets && assets[uuid]?.category?.wall_based) {
    const matrixWorld = meshObject.matrixWorld
    const localYAxis = new Vector3(matrixWorld.elements[4], matrixWorld.elements[5], matrixWorld.elements[6]).normalize()
    const cameraPosition = camera.position.clone()
    const objectPosition = meshObject.position.clone()
    const cameraToObjectDirection = objectPosition.sub(cameraPosition).normalize()
    const dotProduct = localYAxis.dot(cameraToObjectDirection)

    // Set a threshold to determine if the asset is facing the camera
    if (dotProduct > 0) {
      hideAnimation({
        mesh: meshObject,
        animationState: animation,
        setAnimation: setAnimation,
        fadeDuration,
      })
    } else {
      showAnimation({
        mesh: meshObject,
        animationState: animation,
        setAnimation: setAnimation,
        fadeDuration,
      })
    }
  }
}

function tuneModelChildMeshObject(object, gl) {
  if (object.name.toLowerCase() === 'fp') {
    const texture = object.material.map
    texture.minFilter = LinearMipMapLinearFilter
    texture.magFilter = LinearFilter
    texture.anisotropy = gl.capabilities.getMaxAnisotropy()
    texture.needsUpdate = true
  }
  object.castShadow = true
  object.receiveShadow = true
  object.material.envMapIntensity = 200
}

export function InteriorModel({ url, onLoad, assets }) {
  let lastCheck = 0
  const position = [0,0,0]
  const rotation = [0,0,0]
  const checkInterval = 200
  const gltf = useGLTF(`${url}`)
  const { viewport, camera, gl } = useThree()
  const prevPositionRef = useRef(camera.position.clone())
  const debugEnabled = isDebugMode()
  const [animation, setAnimation] = useState('idle')
  const [,setBoundingBox] = useControls('Model', () => (
    {
      max: { value: [0,0,0], disabled: true },
      min: { value: [0,0,0], disabled: true },
      center: { value: [0,0,0], disabled: true },
    }
  ))

  Cache.clear()

  useEffect(() => {
    onLoad(gltf)
    const cameraDirection = new Vector3()
    camera.getWorldDirection(cameraDirection)  // Get the camera's direction in world space

    gltf.scene.position.set(...position)
    gltf.scene.rotation.set(...rotation)
    gltf.scene.traverse((object) => {
      if (object instanceof Mesh) {
        // need this hack since objects from Blender are being exported each time with version prefix e.g 9d4396ae-b508-42cf-b8b4-0ead99fb16d004_1
        const [uuid] = object.name?.split('_')

        if (assets && assets[uuid]?.category?.wall_based) {
          hideOrShowWallBasedItem({
            meshObject: object,
            assets,
            camera,
            cameraDirection,
            hideAnimation: plainHide,
            showAnimation: plainShow,
          })
          if (debugEnabled) {
            const axesHelper = new AxesHelper(3)
            object.add(axesHelper)
          }
        }

        tuneModelChildMeshObject(object, gl)
      }
    })

    const modelBoundingBox = new Box3().setFromObject(gltf.scene) 
    const modelCenter = modelBoundingBox.getCenter(new Vector3())
  
    setBoundingBox({
      max: [...modelBoundingBox.max],
      min: [...modelBoundingBox.min],
      center: [...modelCenter],
    })
  }, [gltf, onLoad, viewport])

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime() * 1000

    // lowering amount of frame animations rerender
    if (elapsedTime - lastCheck > checkInterval) {
      if (!camera.position.equals(prevPositionRef.current)) {
        const cameraDirection = new Vector3()
        camera.getWorldDirection(cameraDirection)  // Get the camera's direction in world space
        
        gltf.scene.traverse((object) => {
          if (object instanceof Mesh) {
            hideOrShowWallBasedItem({
              meshObject: object,
              assets,
              camera,
              cameraDirection,
              animation,
              setAnimation,
            })
          }
        })
      }
      lastCheck = elapsedTime
    }
  }, [camera])

  return <primitive object={gltf.scene} />
}
