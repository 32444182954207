import React, { useCallback, useEffect, useState } from 'react'
import { generatePresignedUrl } from '../../../../../helpers/s3'
import CircularProgress from '../../../../CircularProgress'
import useDetectDevice from '../../../../../hooks/useDetectDevice'
import { sendIFrameEvent } from '../../../../../helpers/iframe'
import Loader from '../../../../Loader'

const maps = {
  'glb_oleh/old_alley.glb': 'glb_oleh/90th_retro_room_with_dendy.glb',
}

const blobMap = new Map()

export const cacheName = 'glb-cache'

window.clearCache = () => {
  caches.delete(cacheName)
}

let controller

const disableCache = localStorage.getItem('cache.disable')

function ModelLoader({ className = '', url, children, showLoader, onLoaded = () => {}, onLoadingChange = () => {} }) {
  const cacheUrl = url.split('?')[0]

  const [progress, setProgress] = useState(0)
  const [blobUrl, setBlobUrl] = useState(null)

  const [loading, setLoading] = useState(true)

  const cacheEnabled = !disableCache

  const [cached, setCached] = useState()

  const onModelLoaded = useCallback(() => {
    onLoaded()
    sendIFrameEvent('model-loaded', {})
  }, [])

  const onModelLoadingChange = useCallback((loading) => {
    onLoadingChange(loading)
    sendIFrameEvent('model-loading-change', { loading })
  }, [])

  useEffect(() => {
    onModelLoadingChange(loading)
  }, [loading, onModelLoadingChange])

  useEffect(() => {
    ;(async () => {
      const cache = await caches.open(cacheName)

      let response = cacheEnabled ? await cache.match(cacheUrl) : null
      setCached(!!response)
      if (!response) {
        console.log('Not found in cache')
        setProgress(0)
        setLoading(true)
        // setDisplayedProgress(0)
      } else {
        console.log('Found in cache')
        const chunks = await processProgress(response)
        onModelLoaded && onModelLoaded()
        setBlobUrl(URL.createObjectURL(new Blob(chunks)))
      }
    })()
  }, [url, cacheEnabled, onModelLoaded])

  async function processProgress(response) {
    const contentLength = response.headers.get('Content-Length')
    let receivedLength = 0
    const reader = response.body.getReader()
    const chunks = []

    while (true) {
      const { done, value } = await reader.read()

      if (done) break

      chunks.push(value)
      receivedLength += value.length

      if (contentLength) {
        setProgress((receivedLength / contentLength) * 100) // Update progress
      }
    }
    return chunks
  }



  useEffect(()=>{
    sendIFrameEvent('model-loading-progress', { progress })
  }, [progress])

  useEffect(() => {
    let controller;
    ;(async () => {
      try {
        if (loading) {
          controller = new AbortController()
          const cache = await caches.open(cacheName)
          let response = cacheEnabled ? await cache.match(cacheUrl) : null
          if (!response) {
            const target = url

            const signal = controller.signal

            response = await fetch(target, { signal })

            const clone = response.clone()

            const chunks = await processProgress(response)

            if (cacheEnabled) {
              await cache.put(cacheUrl, clone)
            }
            onModelLoaded() && onModelLoaded()
            setBlobUrl(URL.createObjectURL(new Blob(chunks)))
          }
          setLoading(false)
        }
      } catch (error) {
        console.error('Error downloading file:', error)
      }
    })()

    return ()=>{
      if (controller) {
        console.log('Cancelling request')
        controller.abort()
        controller = null
      }
    }
  }, [url, loading, cacheEnabled, onModelLoaded])

  const [prevUrl, setPrevUrl] = useState()

  useEffect(() => {
    if (prevUrl && prevUrl !== blobUrl) {
      setTimeout(() => {
        URL.revokeObjectURL(prevUrl)
        // Removing blob url to prevent memory leaks, after 1 second to prevent race conditions.
      }, 1000)
    }
    setPrevUrl(blobUrl)
  }, [blobUrl, prevUrl])

  return (
    <>
      {blobUrl && children(blobUrl)}
      {!cached && showLoader && loading && <LoadingOverlay progress={progress} />}
    </>
  )
}

function LoadingOverlay({ progress, className }) {
  const [displayedProgress, setDisplayedProgress] = useState(0)

  const [showLoaderHint, setShowLoaderHint] = useState(false)

  useEffect(() => {
    if (progress > displayedProgress) {
      setDisplayedProgress(Math.ceil(progress))
    }
  }, [progress, displayedProgress])

  useEffect(() => {
    setTimeout(() => {
      setShowLoaderHint(true)
    }, 1000)
  }, [])

  return (
    <div className="h-full w-full bottom-0 flex justify-center z-[1000]">
      {showLoaderHint && (
        <div
          className={` absolute top-[50%] left-[50%] ml-[-75px] mt-[-75px] z-10 h-[150px] w-[150px] flex flex-col items-center justify-center`}
        >
          <div className={`${!true ? 'bg-[rgba(0,0,0,.2)] backdrop-blur-lg shadow-lg' : ''} rounded-xl`}>
            <div className="scale-[0.8]">
              <Loader />
            </div>
          </div>
          <div
            className={`text-[14px] text-[#3D4256] w-[203px] text-center lg:w-max  ${
              true ? 'opacity-80' : 'hidden'
            } font-medium`}
          >
            {' '}
            Your furnished 3D experience is just moments away!
          </div>
        </div>
      )}
      <div className={`flex items-center p-[12px] fade-in absolute bottom-[24px]  h-[32px] `}>
        {' '}
        <div className="h-[16px] w-[16px] mr-[8px]">
          <CircularProgress value={displayedProgress} />
        </div>{' '}
        <div className="text-[12px] lg:text-[14px] font-medium text-[#3D4256]">
          Loading Models <span className="opacity-50 lg:opacity-80">({displayedProgress.toFixed()}%)</span>
        </div>
      </div>
    </div>
  )
}

export default ModelLoader
