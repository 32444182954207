// src/context/AppConfgContext.js
import React, { createContext, useEffect, useState } from 'react'
import { variants } from '../constants/variant'
import { useStore } from '../store'

// Create the context
export const AppConfigContext = createContext({})

// Create a context provider component
export const AppConfig = ({ children }) => {
  const [config, setConfig] = useState(null)
  const { setConfig: setStoreConfig } = useStore()
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    const variant = process.env.REACT_APP_VARIANT || variants[searchParams.get('variant')]

    const config = {
      variant: variant,
      isCTFApp: variant === variants.ctf,
      isSWApp: variant === variants.sw,
      isEWApp: variant === variants.ew,
      isWideMode: variant === variants.sw || variant === variants.ctf || variant === variants.ew,
      ...Object.fromEntries(searchParams),
    }

    setConfig(config)
    setStoreConfig(config)

    if (config.variant === variants.sw) {
      document.documentElement.style.setProperty('--brand-color', '#4964E0')
      document.title = 'Staging widget - SparX'
      return
    }

    if (config.variant === variants.ew) {
      document.documentElement.style.setProperty('--brand-color', '#3262AC')
      document.title = 'Staging widget - SparX'
      return
    }

    document.title = 'Cort Widget App'
  }, [])

  return <AppConfigContext.Provider value={config}>{config ? children : null}</AppConfigContext.Provider>
}
