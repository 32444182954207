import React, { useEffect, useState } from 'react'
import { cacheName } from '../FloorPlan3DViewer/components/threejs/models'
import { generatePresignedUrl } from '../../helpers/s3'
import { logDebug } from '../../helpers/logger'
import { useParentWindowEvent } from '../../hooks/useParentWindowEvent'

function ModelsPrefetcher() {
  const [urls, setUrls] = useState([])

  useParentWindowEvent('url-prefetch', ({ urls }) => {
    setUrls(urls)
  })

  useEffect(() => {
    ;(async () => {
      // Pre-fetch models for the rest of the plans
      for (const url of urls) {
        const cacheKey = url.split('?')[0]
        const cache = await caches.open(cacheName)
        const has = await cache.match(cacheKey)
        if (!has) {
          console.log(`Pre-fetching model for ${cacheKey}`)
          // If not in cache, fetch from network
          const response = await fetch(url)
          await cache.put(cacheKey, response)
        } else {
          console.log(`${url} already cached`)
        }
      }
    })()
  }, [urls])

  return <></>
}

export default ModelsPrefetcher
