import { useThree } from '@react-three/fiber'
import { Vector3, Box3 } from 'three'

function ScreenshotButton() {
  const { gl, scene, camera, size } = useThree()

  window.captureModelScreenshot = (cb, type) => {
    const screenshotWidth = 300
    const screenshotHeight = 200

    // Save original size and pixel ratio
    const originalWidth = size.width
    const originalHeight = size.height
    const originalPixelRatio = gl.getPixelRatio()

    // Temporarily set the renderer to the desired size
    gl.setPixelRatio(1) // Force pixel ratio to 1 to prevent scaling
    gl.setSize(screenshotWidth, screenshotHeight, false)

    // Update camera aspect ratio and projection matrix
    camera.aspect = screenshotWidth / screenshotHeight
    camera.updateProjectionMatrix()

    if (type === 'top') {
      const box = new Box3().setFromObject(scene)
      const boxSize = box.getSize(new Vector3())
      const boxCenter = box.getCenter(new Vector3())

      // Top-down camera setup
      camera.position.set(boxCenter.x, boxSize.y * 1.5, boxCenter.z)
      camera.lookAt(boxCenter)

      if (camera.isPerspectiveCamera) {
        const maxDim = Math.max(boxSize.x, boxSize.z)
        const distance = maxDim / (2 * Math.tan((camera.fov * Math.PI) / 360))
        camera.position.set(boxCenter.x, boxCenter.y + distance, boxCenter.z)
        camera.lookAt(boxCenter)
      } else if (camera.isOrthographicCamera) {
        camera.zoom = Math.min(screenshotWidth / boxSize.x, screenshotHeight / boxSize.z) / 2
      }
      camera.updateProjectionMatrix()
    }

    // Render and capture the canvas content as a PNG
    gl.render(scene, camera)
    const screenshotURL = gl.domElement.toDataURL('image/png')

    // Revert renderer to the original settings
    gl.setPixelRatio(originalPixelRatio)
    gl.setSize(originalWidth, originalHeight, false)
    camera.aspect = originalWidth / originalHeight
    camera.updateProjectionMatrix()

    // Callback with the screenshot URL
    cb(screenshotURL)
  }

  return null
}

export default ScreenshotButton