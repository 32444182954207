import { Box3, Vector3, Mesh } from 'three'

export function calculateCameraPositionAgainstModel({ model, rotationAngle, fov, aspectRatio, padding = 10 }) {
  // Calculate the model's bounding box
  let modelBox = new Box3().setFromObject(model)
  // NOTE: it's not the best approach but since we had incosistency in models bounding box it's more corect to build bounding box around floor plan
  model.traverse((mesh) => {
    if (mesh instanceof Mesh && mesh.name.toLowerCase() === 'fp') {
      modelBox = new Box3().setFromObject(mesh)
    }
  })
  const { x: modelWidth, z: modelDepth } = modelBox.getSize(new Vector3())
  const fovInRad = (fov * Math.PI) / 180

  // Use the larger of width or depth
  const maxModelSide = Math.max(modelWidth, modelDepth)
  const distanceFromCenter = (maxModelSide + (maxModelSide * padding / 100)) / (2 * Math.tan(fovInRad / 2))
  const radius = (distanceFromCenter * (1 + aspectRatio * (padding * 2 / 100))) / aspectRatio
  const radians = -rotationAngle * Math.PI / 180 // Convert angle to radians

  // Calculate the new camera position
  const x = +(radius * Math.sin(radians)).toFixed(3)
  const z = +(radius * Math.cos(radians)).toFixed(3)
  const y = +(radius/2.5).toFixed(3)

  return [x, y, z]
}

export function calculateCameraDistance({ cameraPosition }) {
  // TODO: has to be enabled back since it's more correct way of calculatin distance to model
  // but 3d artists tend to make mistakes centering position of the model and then everyone points to devs saying that it's regression on our side and we are source of all problems
  // const modelBox = new Box3().setFromObject(model)
  // modelBox.getCenter(center)
  const maxDistance = cameraPosition.distanceTo(new Vector3())

  return {
    near: maxDistance / 100,
    far: maxDistance * 10,
    maxDistance: maxDistance,
    minDistance: maxDistance / 2.5,
  }
}